<template>
  <v-app style="background: #ffffff; ">
    <v-main>
      <div
        v-if="resolutionScreen >= 500"
        class="d-flex justify-center"
        style="height: 100%; background: linear-gradient(to bottom, #4CACBC, #6CC4A1, #B2EBF2, #8dbff2); overflow-x: hidden;"
      >
        <v-layout row wrap align-center justify-center>
          <v-flex class="d-flex justify-center">
            <!-- <v-card
              width="1200"
              outlined
              elevation="24"
              color="white"
              class="mt-n6 rounded-0"
              height="600"
            >
              <v-card-text class="pa-12">
                <v-layout
                  row
                  wrap
                  align-center
                  justify-center
                  fill-height
                  class="ma-0"
                >
                  <v-flex lg12 style="background: white" class="">
                    <v-card-text class="text-center">
                      <h2 class="text py-16 mt-8" style="font-size: 300px;">
                        <br />
                        <span class="text" style="font-size:30px;">
                          ERROR
                        </span>
                        404
                      </h2>
                      <v-card-text class="text-right" style="width: 830px;">
                        <h2
                          class="text py-4 mt-n12 pl-12"
                          style="font-size:30px;"
                        >
                          PAGE NOT FOUND
                        </h2>
                      </v-card-text>
                      <v-btn
                        class="px-3 mt-12"
                        rounded
                        style="font-size:16px;font-weight:600; background-color:#005799; color:#FFFFFF; text-shadow: rgba(0, 0, 0, 0.25)"
                        @click="gohome"
                      >
                        {{$t("landingpagenavbar.home")}}
                      </v-btn>
                    </v-card-text>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card> -->
            <v-card
              width="1200"
              outlined
              elevation="24"
              color="white"
              class="rounded-xl"
              height="600"
            >
              <v-card-text class="pa-12 mt-6">
                <v-layout
                  row
                  wrap
                  align-center
                  justify-center
                  fill-height
                  class="ma-0"
                >
                  <v-flex lg12 style="background: white" class="">
                    <v-card-text class="text-center">
                      <h2
                        class="text text-center py-4 mt-2 mb-n8 white--text"
                        style="font-size:30px;"
                      >
                        ERROR
                      </h2>
                      <h2 class="text py-8 mt-8" style="font-size: 200px;">
                        <br />
                        404
                        <br /><br /><br /><br />
                      </h2>
                      <h2
                        class="text py-4 mt-n1 text-center mb-2"
                        style="font-size:30px;"
                      >
                        PAGE NOT FOUND
                      </h2>
                      <!-- <v-card-text class="text-right" style="width: 830px;">
                        <h2
                          class="text py-4 mt-n12 pl-12"
                          style="font-size:30px;"
                        >
                          PAGE NOT FOUND
                        </h2>
                      </v-card-text> -->
                      <v-btn
                        class="px-3 mt-12"
                        x-large
                        rounded
                        style="font-size:20px;font-weight:600; background-color:#005799; color:#FFFFFF; text-shadow: rgba(0, 0, 0, 0.25)"
                        @click="gohome"
                        width="200"
                      >
                        {{ $t("landingpagenavbar.home") }}
                      </v-btn>
                    </v-card-text>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </div>

      <!-- ขนาดหน้าจอโทรศัพท์ -->
      <div
        v-else
        class="d-flex justify-center"
        style="height: 100%; background: linear-gradient(to bottom, #4CACBC, #6CC4A1, #B2EBF2, #8dbff2); overflow-x: hidden;"
      >
        <v-layout row wrap align-center justify-center>
          <v-flex class="d-flex justify-center">
            <v-card
              width="80%"
              outlined
              elevation="24"
              color="white"
              class="rounded-xl"
              height="600"
            >
              <v-card-text class="pa-12 mt-12">
                <v-layout
                  row
                  wrap
                  align-center
                  justify-center
                  fill-height
                  class="ma-0"
                >
                  <v-flex lg12 style="background: white" class="">
                    <v-card-text class="text-center">
                      <h2
                        class="text text-center py-4 mt-2 mb-n8 white--text"
                        style="font-size:23px;"
                      >
                        ERROR
                      </h2>
                      <h2 class="text py-8 mt-8" style="font-size: 110px;">
                        404
                        <br /><br />
                      </h2>
                      <h2
                        class="text py-4 mt-n1 text-center mb-2"
                        style="font-size:23px;"
                      >
                        PAGE NOT FOUND
                      </h2>
                      <!-- <v-card-text class="text-right" style="width: 830px;">
                        <h2
                          class="text py-4 mt-n12 pl-12"
                          style="font-size:30px;"
                        >
                          PAGE NOT FOUND
                        </h2>
                      </v-card-text> -->
                      <v-btn
                        class="px-3 mt-12"
                        x-large
                        rounded
                        style="font-size:20px;font-weight:600; background-color:#005799; color:#FFFFFF; text-shadow: rgba(0, 0, 0, 0.25)"
                        @click="gohome"
                        width="200"
                      >
                        {{ $t("landingpagenavbar.home") }}
                      </v-btn>
                    </v-card-text>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {},
  data: function() {
    return {};
  },
  computed: {
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  methods: {
    gohome() {
      console.log("goooo");
      this.$router.replace({ path: "/" });
    },
  },
  mounted() {
    this.system_type_run = process.env.VUE_APP_ENVIRONMENT;
  },
  created() {},
};
</script>
<style scoped>
body {
  display: grid;
  justify-content: center;
  align-items: center;

  height: 100vh;
  padding: 0;
  margin: 0;
}
.text {
  background: linear-gradient(to bottom, #4cacbc, #6cc4a1, #8dbff2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
